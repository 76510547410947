import React from "react"
import Divider from "../components/Divider/index"
import Layout from "../components/layout"
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'

export default function About() {
  return (
    <Layout>
      <Seo title="About Us" />
      <div id="about" className="relative">
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-18 flex content-center">
          <div className="mx-auto max-w-3xl px-4 md:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">
            <h1 className="text-3xl lg:text-4xl font-extrabold text-cahoot-blue-med leading-5 tracking-tight font-header text-center">
              About Cahoot
            </h1>
          </div>
        </div>
        <div className="md:-mt-12 lg:-mt-36">
          <Divider color="lightGray1" />
        </div>
        {/* Content start */}
        <div className="bg-cahoot-white -mt-10 pb-24 px-8 text-center md:text-left">
          <div className="lg:mx-auto lg:max-w-3xl font-text">
            {/* Start Person Card */}
              <div className="pt-5 md:pt-0 mt-10 md:flex md:flex-row">
              <div className="md:pr-12 md:pt-16 place-items-center">
                <StaticImage src="../images/JoeStramel.jpg" imgStyle={{ borderRadius: '100%' }} className="rounded-full w-32 h-32" />
                </div>
                <div className="flex flex-col md:w-4/5">
                <div className="py-5 font-black font-header text-2xl text-cahoot-blue-med">Meet Joe Stramel</div>  
                  <p>
                    I am the owner of Apotheosis Technologies and the creator of the Cahoot platform. I've been around computers since the mid-1980s when I was asked to demonstrate the fancy new Apple Macintosh computer to a local community group. I got to miss school and play a simple computer game (think Pong) for an afternoon, and my love of technology was born! Years later, I used my trusty Radio Shack TRS-80 to create a database to hold temperature data. I then plotted a map of Iowa pixel by pixel to display the data and pretend I was a meteorologist (another passion). Yeah, I'm definitely a "geek."
                  </p>
                  <p>I am a proud graduate of the University of Florida, where I received my business degree. I attended college during the dawning of the World Wide Web when dial-up modems were screeching, and the go-to browser was Netscape. I was introduced to HTML, the original language of the web, which started a decades-long journey learning and honing my skills as a developer.</p>
                  <p>I moved to Ohio in the late 1990s and have had a career based in technology ever since. In 2017, I was introduced to the Loyal Order of Moose fraternity and the group's incredible work for its charities and the community. I have served two terms on the Board of Officers at my Lodge, and it was during that time the idea for Cahoot was born.</p>
                  <p>I began what has turned into a two-year journey developing this platform, and I am proud to share the fruits of my labors. I felt that I was in a unique position with my background and experiences to help solve some of our Lodge's problems when dealing with communications. I hope that Cahoot will help my clients improve their exposure within their communities, reach a new audience, and remove the technical obstacles to a successful online presence.</p>
                </div>
            </div>
            {/* End Person Card */}
            {/* Start Person Card */}
              {/* <div className="flex flex-row mt-10">
              <div className="pr-12 pt-16">
                <StaticImage src="../images/Martin_circle.png" className="rounded-full w-32 h-32" />
                </div>
                <div className="flex flex-col w-4/5">
                <div className="py-5 font-black font-header text-2xl text-cahoot-blue-med">Meet Martin</div>  
                  <p>
                    I am the cute, loveable mascot for Cahoot! My designers came up with all sorts of ideas (lots of them having to do with owls), but Joe picked me! Since Cahoot means "partnership," he wanted a fun and friendly character.
                  </p>
                  <p>Kristen, lets have fun with this!</p>
                </div>
            </div> */}
            {/* End Person Card */}
          </div>
        </div>
      </div>

    </Layout>
  )
}
